import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyModal = lazy(
  () => import("./modal-DklgPCux.js").then((module) => ({
    default: module.Modal
  }))
);
function Modal({
  id: _id,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyModal, { ...props });
}
const useModalContent = () => {
  return useMemo(
    () => ({
      modal: {
        component: Modal
      }
    }),
    []
  );
};
export {
  LazyModal as Modal,
  useModalContent
};
